import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.container = {
      px: 10,
      py: 12,
      maxWidth: '1000px',
      margin: '0 auto',
      a: {
        textDecoration: 'underline',
      },
    };
    this.title = {
      variant: 'text.h4',
      mb: '14px',
    };
    this.button = {
      mt: '16px',
    };
  })()
);
