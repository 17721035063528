import { useEffect } from 'react';

import { useDataLayerActions } from '@hooks';

export const usePageDataLayerEvents = ({ page }) => {
  const { sendViewCollectionEvent, sendViewProductBundleEvent } =
    useDataLayerActions();

  // 'dl_view_item_list' event for super collection
  useEffect(() => {
    if (!page?.sections?.length) return;
    const superCollections = page.sections.filter((section) => {
      return (
        section._template === 'super-collection' &&
        section.sectionVisibility === 'visible'
      );
    });
    if (!superCollections?.length) return;

    const allProducts = superCollections?.reduce((acc, col) => {
      const products = col.products?.products?.map(({ product }) => ({
        handle: product?.handle,
      }));
      if (!products?.length) return acc;
      return [...acc, ...products];
    }, []);

    if (!allProducts?.length) return;

    sendViewCollectionEvent({
      products: allProducts,
      collectionId: `/page/${page.handle}`,
    });
  }, [page?.id, sendViewCollectionEvent]);

  // 'dl_view_item' event for kit
  useEffect(() => {
    if (!page?.sections?.length) return;
    const kit = page.sections.find((section) => {
      return (
        section._template === 'kit' && section.sectionVisibility === 'visible'
      );
    });
    if (!kit?.products?.list?.length) return;

    sendViewProductBundleEvent({
      products: kit.products.list.map((item) => item.product),
    });
  }, [page?.id, sendViewProductBundleEvent]);

  // 'dl_view_item_list' event for kits collection
  useEffect(() => {
    if (!page?.sections?.length) return;
    const kitsCollection = page.sections.find((section) => {
      return (
        section._template === 'kits-collection' &&
        section.sectionVisibility === 'visible'
      );
    });
    if (!kitsCollection?.kits?.length) return;

    const allProducts = kitsCollection?.kits.reduce((acc, kit) => {
      const products = kit.products?.map(({ product }) => ({
        handle: product?.handle,
      }));
      if (!products?.length) return acc;
      return [...acc, ...products];
    }, []);

    if (!allProducts?.length) return;

    sendViewCollectionEvent({
      products: allProducts,
      collectionId: `/page/${page.handle}`,
    });
  }, [page?.id, sendViewCollectionEvent]);
};
