import { useCallback } from 'react';

import store, { useRecoilState, useRecoilValue } from '@store';

export const useSearchSort = () => {
  const searchPageData = useRecoilValue(store.searchPageData);
  const [selectedSort, setSelectedSort] = useRecoilState(
    store.searchSelectedSort
  );


  const sortCollection = useCallback(async ({ field, direction }) => {
    if (!field || !direction) {
      // clear sort
      setSelectedSort(null);
      return;
    }

    // Examples
    // { field: 'ss_price', direction: 'asc' },
    // { field: 'title', direction: 'desc' }
    // { field: 'ss_days_since_published', direction: 'asc' }

    setSelectedSort({ field, direction });
  }, []);

  return [
    // state
    {
      selectedSort,
      sortOptions: searchPageData?.sorting?.options || null,
    },
    // actions
    {
      sortCollection,
    },
  ];
};
