import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.container = {
      minWidth: '15%',
      position: 'sticky',
      height: '100% ',
      position: 'sticky',
      top: '15%',
      overflow: 'hidden',
      mt: 48,
      mb: 10,
    };
  })()
);
