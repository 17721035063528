/* eslint-disable import/no-unresolved */
import React, { useEffect } from 'react';

import * as getStatic from '@backpackjs/core-nextjs/get-static';
import { store } from '@backpackjs/storefront';

import PageTemplate from '../../templates/page';
import { PageErrorBoundary, PageHead } from '../../_components';

export const getStaticPaths = getStatic.paths.page;
export const getStaticProps = getStatic.props.page;

export default function Page({ isPreview, renderSections, ...props }) {
  const { page } = props;

  const setPage = store.recoil.useRecoilState(store.state.page)[1];

  useEffect(() => {
    setPage(page);
  }, [page?.handle]);

  return (
    <PageErrorBoundary>
      <PageHead page={page}>
        <PageTemplate {...props} renderSections={renderSections} />
      </PageHead>
    </PageErrorBoundary>
  );
}
