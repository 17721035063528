import { useCallback } from 'react';

import store, {
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from '@store';

export const useSearchFilters = () => {
  const searchPageData = useRecoilValue(store.searchPageData);

  const [selectedFilters, setSelectedFilters] = useRecoilState(
    store.searchSelectedFilters
  );

  const [currentResultsPage, setCurrentResultsPage] = useRecoilState(
    store.searchCurrentResultsPage
  );
  const selectedFiltersDep = JSON.stringify(selectedFilters);

  // get filter entries based on type
  const getFilterEntries = useCallback(({ field, option }) => {
    let filters = {};

    // if option type is range, separate into low and high entries
    if (option.type === 'range') {
      const [key1, value1] = [`filter.${field}.low`, option.low];
      const [key2, value2] = [`filter.${field}.high`, option.high];
      filters = {
        [`${key1}.${value1}`]: { key: key1, value: value1 },
        [`${key2}.${value2}`]: { key: key2, value: value2 },
      };
    } else {
      const [key, value] = [`filter.${field}`, option.value || ''];
      filters = {
        [`${key}.${value}`]: { key, value },
      };
    }

    return filters;
  }, []);

  // add to filters using filterOptions
  const addToSelectedFilters = useCallback(
    ({ field, option }) => {
      if (!field || !option) {
        return console.error('addToSelectedFilters: missing field or option');
      }
      // Example ss field: 'product_type'
      // Example ss option: { active: false, type: 'value', value: 'Shirts' ... }

      // create map of previous selected filters
      const selectedFiltersMap = selectedFilters?.reduce((obj, filter) => {
        obj[`${filter.key}.${filter.value}`] = filter;
        return obj;
      }, {});

      // prevent duplicates by spreading previous and new filters into new map
      const newSelectedFiltersMap = {
        ...selectedFiltersMap,
        ...getFilterEntries({ field, option }),
      };

      // convert filters map back into array
      const newSelectedFilters = Object.values(newSelectedFiltersMap);

      setSelectedFilters(newSelectedFilters);
      setCurrentResultsPage(1);
    },
    [selectedFiltersDep, selectedFilters]
  );

  // remove from filters using filterSummary
  const removeFromSelectedFilters = useCallback(
    ({ field, option }) => {
      if (!field || !option) {
        return console.error(
          'removeFromSelectedFilters: missing field or option'
        );
      }
      // Example ss field / option: 'collection_name', { value: 'Shirts', type: 'value }
      // Example ss field / option w/ range: 'ss_price', { high: '80', low: '60', type: 'range }

      if (!selectedFilters?.length) return null;

      // filter out filters that match the field and option
      const newSelectedFilters = selectedFilters.filter((filter) => {
        if (option.type === 'range') {
          const isEntryLow =
            filter.key === `filter.${field}.low` && filter.value === option.low;

          const isEntryHigh =
            filter.key === `filter.${field}.high` &&
            filter.value === option.high;

          return !isEntryLow && !isEntryHigh;
        }
        const isEntry =
          filter.key === `filter.${field}` && filter.value === option.value;

        return !isEntry;
      });

      setSelectedFilters(newSelectedFilters);
      setCurrentResultsPage(1);

    },
    [selectedFiltersDep, selectedFilters]
  );

  // clear filters
  const clearSelectedFilters = useCallback(() => {
    setSelectedFilters(null);
    setCurrentResultsPage(1);
  }, []);

  return [
    // state
    {
      selectedFilters,
      filters: searchPageData?.facets || null,
      filterSummary: searchPageData?.filterSummary || null,
    },
    // actions
    {
      addToSelectedFilters,
      removeFromSelectedFilters,
      clearSelectedFilters,
    },
  ];
};
