import dynamic from 'next/dynamic';
import { Flex } from 'theme-ui';

import { themed } from './Sort.theme';
import { useSearchSort } from './useSearchSort';

const SortDropdown = dynamic(
  () => import('./SortDropdown').then((module) => module.SortDropdown),
  { ssr: false, suspense: false }
);

export const Sort = themed(({ theme }) => {
  const [{ sortOptions }, { sortCollection }] = useSearchSort();
  return (
    <Flex
      data-comp={Sort.displayName}
      variant="flex.row.end"
      sx={theme.wrapper}
    >
      <SortDropdown options={sortOptions} handleSelect={sortCollection} />
    </Flex>
  );
});

Sort.displayName = 'Sort';
