import { useState } from 'react';
import { useSettings } from '@backpackjs/storefront';
import { Container, Flex } from 'theme-ui';

import { useBreakpointValue, useBreakpoint, usePickedSections } from '@hooks';

import { Accessibility } from './Accessibility';
import { SearchTemplate } from './search';
import { PagesSidebar } from './sidebar/PagesSidebar';
import { CollectionsControls } from './Controls/CollectionsControls';
import { usePageDataLayerEvents } from './usePageDataLayerEvents';

export const PageTemplate = ({ page, renderSections, ...props }) => {
  const sections = renderSections();
  usePageDataLayerEvents({ page });

  const hasSidebar = sections?.some(
    (section) => section?.props?.['data-comp'] === 'side-bar'
  );

  const { anyOf, otherSections } = usePickedSections({
    anyOf: hasSidebar
      ? ['subcategory', 'super-collection', 'side-bar']
      : ['subcategory'],
    sections,
  });

  const [hideSidebar, setHideSidebar] = useState(false);
  const showSidebar = useBreakpointValue([false, false, true]) && !hideSidebar;

  const settings = useSettings();
  const superCollectionSettings = settings?.superCollection || {
    viewPerRowDesktop: 3,
    viewPerRowMobile: 2,
  };

  const { l_, xl_ } = useBreakpoint();
  const isDesktop = l_ || xl_;

  const { showFlatModelOptionMens, showFlatModelOptionWomens } =
    superCollectionSettings;

  const isWomen = page?.handle.includes('women' || 'womens');
  const isFridayProjects = page?.handle.includes('the-friday-projects');

  const showControls =
    anyOf['super-collection']?.length > 0 &&
    (isWomen ? showFlatModelOptionWomens : showFlatModelOptionMens);

  return (
    <Container data-comp="PageTemplate">
      <SearchTemplate page={page} {...props} />

      <Accessibility page={page} />

      {otherSections}

      <Flex
        sx={{
          flexDirection: 'row',
          display: isFridayProjects ? 'none' : 'flex',
        }}
      >
        <PagesSidebar showSidebar={showSidebar}>
          {anyOf['side-bar']?.slice(0, 1) || null}
        </PagesSidebar>

        <Flex
          sx={{
            flexDirection: 'column',
            flexBasis: !showSidebar ? ['100%'] : ['100%', '85%'],
            px: [4, 15],
            pt: 10,
          }}
        >
          {anyOf.subcategory || null}

          {showControls && (
            <CollectionsControls
              isDesktop={isDesktop}
              hideSidebar={hideSidebar}
              setHideSidebar={setHideSidebar}
              isSuperCollection={!!anyOf['super-collection']}
            />
          )}

          {anyOf['super-collection'] || null}
        </Flex>
      </Flex>
    </Container>
  );
};
