import { Flex, Paragraph } from 'theme-ui';

import { Svg, Dropdown } from '@snippets';

import { themed } from './ResultsPerPage.theme';

export const ResultsPerPage = themed(({ theme, options, handleSelect }) => {
  return options?.length ? (
    <Dropdown
      data-comp={ResultsPerPage.displayName}
      initial=""
      onSelected={({ value }) => handleSelect(value)}
      sx={theme.dropdown}
    >
      <Dropdown.Selected sx={theme.selected}>
        {({ selecting, selected }) => {
          return (
            <Flex sx={theme.selected.content}>
              <Paragraph sx={theme.selected.title}>
                {selected.label || 24} results
              </Paragraph>
              <Svg
                alt="chevron"
                viewBox="0 0 48 48"
                src="/svgs/chevron/down.svg#down"
                sx={{
                  ...theme.selected.icon,
                  transform: selecting ? 'rotate(180deg)' : 'rotate(0deg)',
                }}
              />
            </Flex>
          );
        }}
      </Dropdown.Selected>

      {options.map((item, index) => {
        return (
          <Dropdown.Option key={index} option={item} sx={theme.option}>
            {({ selecting, isSelected, option }) =>
              isSelected ? null : (
                <Paragraph sx={theme.option.title}>{option.label}</Paragraph>
              )
            }
          </Dropdown.Option>
        );
      })}
    </Dropdown>
  ) : null;
});

ResultsPerPage.displayName = 'ResultsPerPage';
