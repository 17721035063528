import { Box, Checkbox, Label, Paragraph } from 'theme-ui';

import { Accordion, Accordions } from '@snippets/Accordions';

import { useSearchFilters } from './useSearchFilters';

export const SearchFilters = ({}) => {
  const [{ filters }, { addToSelectedFilters, removeFromSelectedFilters }] =
    useSearchFilters();

  return (
    <Box data-comp={SearchFilters.displayName}>
      {filters?.length ? (
        <Accordions
          textColor="black"
          borderColor="mediumLight"
          iconColor="black"
          minHeight="36px"
          maxHeight="100%"
          sx={{ maxHeight: '100%' }}
        >
          {filters.map((facet, index) => {
            return (
              <Accordion theme="light" key={index}>
                <Accordion.Header>
                  <Paragraph>{facet.label}</Paragraph>
                </Accordion.Header>

                <Accordion.Body sx={{ maxHeight: '100%' }}>
                  {facet.values.map((value, index) => {
                    return (
                      <Label sx={{ alignItems: 'center' }} key={index}>
                        <Checkbox
                          // defaultChecked={false}
                          checked={value.active}
                          // onChange={() => handleChecked(facet, value)}
                          onChange={() => {
                            value.active
                              ? removeFromSelectedFilters({
                                  field: facet?.field,
                                  option: value,
                                })
                              : addToSelectedFilters({
                                  field: facet?.field,
                                  option: value,
                                });
                          }}
                        />
                        {value.label}
                      </Label>
                    );
                  })}
                </Accordion.Body>
              </Accordion>
            );
          })}
        </Accordions>
      ) : null}
    </Box>
  );
};

SearchFilters.displayName = 'SearchFilters';
