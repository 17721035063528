import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      variant: 'layout.content',
      pt: [14, 16],
      pb: [14, 18, '80px'],
    };

    this.heading = {
      variant: 'text.h2',
      textAlign: 'center',
    };

    this.grid = {
      display: 'grid',
      columnGap: [10, 12],
      rowGap: [14, 12],
    };

    this.noProducts = {
      variant: 'flex.column',

      text: {
        variant: 'text.lg',
        mt: 20,
        textAlign: 'center',
      },
    };
    this.info = {
      py: ['72px', '72px', '96px'],
      alignItems: 'center',
      justifyContent: 'center',
      variant: 'text.h2',
      borderBottom: '1px solid',
      borderBottomColor: 'lightGray',
    };
    this.list = {
      display: 'grid',
      gridGap: '20px 40px',
      flexWrap: 'wrap',
      px: [8, 12, 20],
      py: ['50px', null, '84px'],
      gridTemplateColumns: [
        'repeat(2, 1fr)',
        'repeat(3, 1fr)',
        'repeat(4, 1fr)',
      ],
    };
    this.banner = (isHtmlBanner) => ({
      width: '100%',
      height: ['100%', 'calc(100% - 60px)'],
      'h2,h3,h4,h5,h6': {
        variant: 'text.h4',
        mt: [10, 11],
        mb: 3,
      },
      p: {
        mt: 0,
        variant: 'text.lg',
      },
      'p:last-of-type': {
        mb: 0,
      },
      img: {
        maxHeight: isHtmlBanner ? '60px' : 'unset',
        maxWidth: isHtmlBanner ? '60px' : 'unset',
      },
    });
  })()
);
