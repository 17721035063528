import React from 'react';
import { Box, Button, Link, Paragraph } from 'theme-ui';
import { themed } from './Accessibility.theme';

export const Accessibility = themed(({ theme, page }) => {
  if (page?.handle !== 'our-commitment-to-accessibility') return null;
  return (
    <Box sx={theme.container}>
      <Paragraph sx={theme.title}>Our Commitment to Accessibility</Paragraph>
      <Box variant="text.base">
        We are committed to making our website’s content accessible and user
        friendly to everyone. If you are having difficulty viewing or navigating
        the content on this website, or notice any content, feature, or
        functionality that you believe is not fully accessible to people with
        disabilities, please email our team at{' '}
        <Link style={{ color: 'black' }} href="mailto:nfo@cutsclothing.com">
          info@cutsclothing.com
        </Link>{' '}
        with “Disabled Access” in the subject line and provide a description of
        the specific feature you feel is not fully accessible or a suggestion
        for improvement. We take your feedback seriously and will consider it as
        we evaluate ways to accommodate all of our customers and our overall
        accessibility policies. Additionally, while we do not control such
        vendors, we strongly encourage vendors of third-party digital content to
        provide content that is accessible and user friendly.
      </Box>
      <Button
        data-acsb-custom-trigger="true"
        variant="buttons.primary"
        sx={theme.button}
      >
        Accessibility Options
      </Button>
    </Box>
  );
});
