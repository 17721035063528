import { useState, useEffect } from 'react';
import { Button, Flex } from 'theme-ui';
import store, { useRecoilState } from '@store';
import { Switch, View } from '@snippets';
import { useSidebar } from '@hooks';

export const CollectionsControls = ({
  isDesktop,
  hideSidebar,
  setHideSidebar,
  isSuperCollection,
}) => {
  const [invertModelPictures, setInvertModelPictures] = useRecoilState(
    store.invertModelPictures
  );
  const [{}, { openFilterSidebar }] = useSidebar();

  const [invertImages, setInvertImages] = useState(false);

  useEffect(() => {
    setInvertModelPictures(invertImages);
  }, [invertImages]);

  return (
    <Flex
      variant={isDesktop ? 'flex.row.between.center' : 'flex.row.end.center'}
    >
      {/* <Button
        data-comp={'FiltersSidebar'}
        onClick={openFilterSidebar}
        variant="buttons.withArrow"
        aria-label="Open filters sidebar"
        sx={{
          height: 'fit-content',
          p: 0
        }}
      >
        Filter By
      </Button> */}

      {isDesktop && (
        <Button
          variant="plain"
          sx={{
            fontSize: '14px',
            color: 'gray.dark',
            mr: '16px',
            height: '32px',
            '@media screen and (min-width: 480px)': {
              '&:hover': {
                color: 'black',
              },
            },
          }}
          onClick={() => setHideSidebar(!hideSidebar)}
        >
          {hideSidebar ? 'Show Menu' : 'Hide Menu'}
        </Button>
      )}

      <Flex variant="flex.row.center">
        {!isSuperCollection && <View isDesktop={isDesktop} />}
        <Switch
          leftLabel="Flat"
          rightLabel="Model"
          isSelected={invertImages}
          onCheck={() => setInvertImages((invertImages) => !invertImages)}
        />
      </Flex>
    </Flex>
  );
};

CollectionsControls.displayName = 'CollectionsControls';
