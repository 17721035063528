import { useEffect, useState } from 'react';
import { Container, Flex, Box, Heading, Text } from 'theme-ui';
import { useRouter } from '@backpackjs/storefront';

import { useSearch } from '@snippets/Search/hooks';

import { ProductItem } from '@snippets';
import store, { useRecoilState, useRecoilValue } from '@store';
import { useDataLayerActions } from '@hooks';

import { Pagination } from '@snippets/Search/SearchPage/Pagination';
import { Sort } from '@snippets/Search/SearchPage/Sort';
import { SearchPageSuggestions } from '@snippets/Search/SearchPage';
import { ResultsPerPage } from '@snippets/Search/SearchPage/ResultsPerPage';
import { themed } from './search.theme';

export const SearchTemplate = themed(({ theme, page }) => {
  if (page?.handle !== 'search') return null;

  const [resultsPerPage, setResultsPerPage] = useState(24);

  const [
    { pageResults, pageSuggested, totalPageResults, merchandising },
    { getSearchResults, getSearchSuggestions },
  ] = useSearch();
  const router = useRouter();
  const { sendViewSearchResultsEvent } = useDataLayerActions();

  const [currentResultsPage, setCurrentResultsPage] = useRecoilState(
    store.searchCurrentResultsPage
  );

  const [selectedFilters] = useRecoilState(store.searchSelectedFilters);
  const selectedSort = useRecoilValue(store.searchSelectedSort);

  const query = router.query?.query || '';
  const tag = router.query?.tag || '';
  const header = merchandising?.content?.header?.[0];
  const footer = merchandising?.content?.footer?.[0];
  const banner = merchandising?.content?.banner?.[0];
  const left = merchandising?.content?.left?.[0];

  const isAutocomplete = pageSuggested?.type === 'completed';
  const searchTerm = isAutocomplete ? pageSuggested.text : query;

  useEffect(() => {
    getSearchSuggestions({ query, isPage: true });
  }, [query]);

  useEffect(() => {
    getSearchResults({
      query: searchTerm,
      isPage: true,
      resultsPerPage,
      tag,
      sort: selectedSort,
    });
  }, [
    searchTerm,
    currentResultsPage,
    selectedSort,
    resultsPerPage,
    selectedFilters,
  ]);

  useEffect(() => {
    setCurrentResultsPage(currentResultsPage);
  }, [query]);

  useEffect(() => {
    sendViewSearchResultsEvent({
      products: pageResults,
      isSearchPage: true,
      searchTerm: query,
    });
  }, [pageResults, sendViewSearchResultsEvent]);

  return (
    <Container data-comp="SearchTemplate">
      {!tag && (
        <Flex
          sx={{
            variant: 'flex.column.center',
            mt: 13,
            mb: 13,
            maxWidth: '700px',
            mx: 'auto',
          }}
        >
          <Heading as="h1" sx={theme.heading}>
            {isAutocomplete ? '0' : totalPageResults} results for "{query || ''}
            "
          </Heading>
          {isAutocomplete && (
            <Heading
              sx={{
                textAlign: 'center',
                variant: 'text.lg',
                mt: 10,
              }}
            >
              Showing {totalPageResults} results for{' '}
              <Text sx={{ fontStyle: 'italic', fontWeight: 700 }}>
                {pageSuggested.text || ''}
              </Text>{' '}
              instead
            </Heading>
          )}
          <SearchPageSuggestions />
        </Flex>
      )}

      {/* Header and banner merchandising banner */}
      {(header || banner) && (
        <Box sx={{ mb: 12, display: ['none', 'block'] }}>
          {[header, banner].map((item, index) =>
            item ? (
              <Box
                key={index}
                dangerouslySetInnerHTML={{
                  __html: item,
                }}
              />
            ) : null
          )}
        </Box>
      )}
      <Flex
        sx={{
          justifyContent: 'space-between',
          px: 30,
          alignItems: 'center',
          flexDirection: ['column', 'row'],
          my: [30, 0],
        }}
      >
        {/* Hide for now */}
        {totalPageResults < -1 && (
          <ResultsPerPage
            options={[
              { label: '24', value: 24 },
              { label: '48', value: 48 },
              { label: '72', value: 72 },
              { label: '80', value: 80 },
            ]}
            handleSelect={setResultsPerPage}
          />
        )}

        {totalPageResults > 0 && <Sort />}
      </Flex>

      <Flex sx={{ width: '100%' }}>
        {/* Left merchandising banner */}
        {left && (
          <Box
            dangerouslySetInnerHTML={{
              __html: left,
            }}
            sx={{ maxWidth: '200px', mr: 12, display: ['none', 'block'] }}
          />
        )}

        {/* Results */}
        <Container
          sx={{
            display: 'flex',
            flexDirection: ['column', 'row'],
          }}
        >
          {/* <Box sx={{ mx: 15, width: ['80%', '15%'] }}>
            <SearchFilters />
          </Box> */}
          <Flex sx={{ ...theme.list, flex: 1 }}>
            {totalPageResults
              ? pageResults.map((product, index) => {
                  if (!product) return null;
                  let isHtmlBanner = false;
                  if (product.isBanner) {
                    isHtmlBanner = product.value.includes(
                      'data-banner-type="html"'
                    );
                  }
                  return !product.isBanner ? (
                    <ProductItem
                      key={product.id}
                      product={product}
                      index={index}
                      searchTerm={query}
                    />
                  ) : (
                    // Inline merchandising banner
                    <Box
                      key={product.id}
                      sx={theme.banner(isHtmlBanner)}
                      dangerouslySetInnerHTML={{
                        __html: product.value,
                      }}
                    />
                  );
                })
              : null}
          </Flex>
        </Container>
      </Flex>

      {totalPageResults > 0 && <Pagination />}

      {/* Footer merchandising banner */}
      {footer && (
        <Box
          dangerouslySetInnerHTML={{
            __html: footer,
          }}
          sx={{ mt: 12, display: ['none', 'block'] }}
        />
      )}
    </Container>
  );
});
